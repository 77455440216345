<template>
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.61607 0.5625C3.75 0.21875 4.04464 0 4.36607 0H7.60714C7.92857 0 8.22321 0.21875 8.35714 0.5625L8.57143 1H11.1429C11.5982 1 12 1.46875 12 2C12 2.5625 11.5982 3 11.1429 3H0.857143C0.375 3 0 2.5625 0 2C0 1.46875 0.375 1 0.857143 1H3.42857L3.61607 0.5625ZM0.830357 4H11.1429V14C11.1429 15.125 10.3661 16 9.42857 16H2.54464C1.60714 16 0.830357 15.125 0.830357 14V4ZM2.97321 6.5V13.5C2.97321 13.7813 3.1875 14 3.40179 14C3.64286 14 3.83036 13.7813 3.83036 13.5V6.5C3.83036 6.25 3.64286 6 3.40179 6C3.1875 6 2.97321 6.25 2.97321 6.5ZM5.54464 6.5V13.5C5.54464 13.7813 5.75893 14 5.97321 14C6.21429 14 6.42857 13.7813 6.42857 13.5V6.5C6.42857 6.25 6.21429 6 5.97321 6C5.75893 6 5.54464 6.25 5.54464 6.5ZM8.14286 6.5V13.5C8.14286 13.7813 8.33036 14 8.57143 14C8.78571 14 9 13.7813 9 13.5V6.5C9 6.25 8.78571 6 8.57143 6C8.33036 6 8.14286 6.25 8.14286 6.5Z"
      fill="#303030"
    />
  </svg>
</template>
